<template>
<cui-acl  :roles="['talkmoreB2Cedit']" redirect v-if="loaded">
    <a-form @submit.prevent="editSale()">
        <h2>Endre Salg på Talkmore Privat</h2>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <a-form-item label="Kampanje" :required="true">
                  <a-select
                      v-model="data.campaign"
                      :options="campaigns"
                    />
                </a-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
        <div class="card-body">
          <h5 class="text-black mt-2 mb-3">Status</h5>
          <a-form-item label="Status salg">
            <a-select
              ref="select"
              v-model="data.status"
              :options="options1"
              style="width: 300px"
            ></a-select>
          </a-form-item>
        </div>
        </div>
        <div class="card">
        <div class="card-body">
          <h5 class="text-black mt-2 mb-3">Salginfo</h5>
          <div class="row">
            <div class="col-md-4" v-if="!newNumber">
              <a-form-item label="Telefonnumer" :required="true">
              <a-input placeholder="Telefonnummer" v-model="data.number" @change="checkNumber(target)"/>
              </a-form-item>
            </div>
            <div class="col-md-2">
              <a-form-item label="Nytt nummer?">
              <a-radio-group v-model="newNumber">
                  <a-radio :style="radioStyle" :value="true">
                  Ja
                  </a-radio>
                  <a-radio :style="radioStyle" :value="false">
                  Nei
                  </a-radio>
              </a-radio-group>
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Mersalg">
                <a-checkbox-group
                :options="extraproducts"
                v-model="data.extra"
                :value="[]"
                @change="addExtra"
                />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Dato salg" :required="true">
              <a-date-picker v-model="date"/>
              </a-form-item>
            </div>
          </div>
          </div>
        </div>
        <div class="card">
        <div class="card-body">
          <h5 class="text-black mt-2 mb-3">Produkt</h5>
          <div class="row">
            <div class="col-md-6">
              <a-form-item label="Produkt" :required="true">
                <a-auto-complete
                  v-model="data.product"
                  :data-source="products"
                  :filter-option="filterOption"
                  style=""
                  placeholder="finn produkt..."
                  @change="checkFamily"
                />
                </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Er det et familiesalg eller samlerabatt?">
              <a-radio-group v-model="multipleSale">
                  <a-radio :style="radioStyle" :value="true">
                  Ja
                  </a-radio>
                  <a-radio :style="radioStyle" :value="false">
                  Nei
                  </a-radio>
              </a-radio-group>
              </a-form-item>
            </div>
          </div>
          <div v-if="multipleSale">
            <div v-if="hasChildren">
            <div v-for="(child, key) in data.children" :key="key">
              <p>Familiemedlem {{ key+1 }}</p>
              <div class="row">
              <div class="col-md-3" v-if="!child.newNumber">
                <a-form-item label="Telefonnumer" :required="true">
                <a-input placeholder="Telefonnummer" v-model="child.number" @change="checkNumber(target)"/>
                </a-form-item>
              </div>
              <div class="col-md-2">
                <a-form-item label="Nytt nummer?">
                <a-radio-group v-model="child.newNumber">
                    <a-radio :style="radioStyle" :value="true">
                    Ja
                    </a-radio>
                    <a-radio :style="radioStyle" :value="false">
                    Nei
                    </a-radio>
                </a-radio-group>
                </a-form-item>
              </div>
              <div class="col-md-4" v-if="!family">
                <a-form-item label="Produkt">
                <a-auto-complete
                  v-model="child.product"
                  :data-source="products"
                  :filter-option="filterOption"
                  style=""
                  placeholder="finn produkt..."
                />
                </a-form-item>
              </div>
              <div class="col-md-3">
                <a-form-item label="Mersalg">
                  <a-checkbox-group
                  :options="extraproducts"
                  v-model="child.extra"
                  :value="[]"
                  @change="addExtra"
                  />
                </a-form-item>
              </div>
              </div>
            </div>
            </div>
            <a-button style="width: 150px;" type="primary" class="mr-3" @click.prevent="addMember()">Ny abo</a-button>
          </div>
        </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="text-black mt-2 mb-3">Kommentar</h5>
            <a-textarea placeholder="Kommentar" :rows="4" v-model="data.comment"/>
          </div>
        </div>
        <div class="card">
        <div class="card-body">
          <h5 class="text-black mt-2 mb-3">Selger</h5>
          <div class="row">
              <div class="col-md-6">
                <a-form-item label="Selger">
                <a-auto-complete
                  v-model="data.tempSeller"
                  :data-source="users"
                  :filter-option="filterOption"
                  style=""
                  placeholder="finn selger..."
                />
                </a-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <a-form-item label="Ble salget gjennomført på en annen EP-bruker">
                <a-radio-group v-model="otherEP">
                    <a-radio :style="radioStyle" :value="true">
                    Ja
                    </a-radio>
                    <a-radio :style="radioStyle" :value="false">
                    Nei
                    </a-radio>
                </a-radio-group>
                </a-form-item>
              </div>
              <div class="col-md-6" v-if="otherEP">
                <a-form-item label="EP bruker">
                <a-auto-complete
                  v-model="data.epuser"
                  :data-source="epusers"
                  :filter-option="filterOption"
                  style=""
                  placeholder="finn ep bruker..."
                />
                </a-form-item>
              </div>
            </div>
        </div>
        </div>
      <a-button style="width: 150px;" type="primary" htmlType="submit" class="mr-3" :loading="loading">Oppdater salg</a-button>
      <a-popconfirm
        title="Sure to delete?"
        @confirm.prevent="() => onDelete()"
      >
        <a-button style="width: 150px;" type="secondary" class="mr-3">Slett salg</a-button>
      </a-popconfirm>
      <a-button style="width: 150px;" type="error" class="mr-3" @click.prevent="$router.push({ path: `/talkmoreB2C/sales/` })">Tilbake</a-button>
    </a-form>
</cui-acl>
</template>

<script>
import { firestore } from '@/services/firebase'
import cuiAcl from '@/components/cleanui/system/ACL'
import moment from 'moment'
import { mapState } from 'vuex'
import { openNotification } from '@/services/powerAutomate'

const campaigns = [
  {
    label: 'Nysalg',
    value: 'newsale',
  },
  {
    label: 'Bytt.no',
    value: 'byttno',
  },
  {
    label: 'Biovita',
    value: 'biovita',
  },
  {
    label: 'Lead Ads',
    value: 'leadads',
  },
]

export default {
  computed: {
    ...mapState(['user']),
  },
  components: {
    cuiAcl,
  },
  data() {
    return {
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      data: {},
      loading: false,
      otherSeller: false,
      otherEP: false,
      newNumber: false,
      multipleSale: false,
      family: false,
      products: [],
      extraproducts: [],
      epusers: [],
      users: [],
      loaded: false,
      hasChildren: false,
      date: '',
      options1: [
        {
          value: '01 registered',
          label: '01 Registered',
        },
        {
          value: '02 port error B2C',
          label: '02 Port error Privat',
        },
        {
          value: '03 port error B2B',
          label: '03 Port error Bedirft',
        },
        {
          value: '10 already active in database',
          label: '10 Already active in database',
        },
        {
          value: '11 cancelled by customer',
          label: '11 Cancelled by customer',
        },
        {
          value: '12 Not uploaded in SalesReport',
          label: '12 Not uploaded in SalesReport',
        },
      ],
      campaigns,
    }
  },
  methods: {
    moment,
    checku(e) {
      console.log(e)
      console.log(this.data.children)
    },
    onDelete() {
      firestore.collection('tmb2c-sales').doc(this.$route.params.id).delete()
        .then(() => {
          openNotification('Salg slettet')
          this.$router.push({ path: '/talkmoreB2C/sales/' })
        }).catch(err => {
          openNotification('Feil ved sletting av salg', err.message)
        })
    },
    editSale() {
      const data = this.data
      const user = this.users.filter(obj => obj.id === this.user.id)[0]
      console.log(user)
      data.epuser = this.otherEP ? data.epuser : user.epuser
      const otherSeller = this.users.find(obj => obj.value === data.tempSeller)
      data.seller = {
        name: otherSeller.text,
        id: otherSeller.value,
      }
      // remove children without phonenumber
      if (data.children) {
        data.children = data.children.filter(obj => {
          if (!obj.newNumber) {
            return obj.number ? obj : ''
          } else {
            return {
              newNumber: obj.newNumber,
              number: '',
              product: obj.product,
            }
          }
        })
      }
      // check if all children have product
      let error = false
      if (this.multipleSale && !this.family) {
        data.children.forEach(obj => {
          if (!obj.product) {
            error = true
          }
        })
      }
      if (data.epuser) {
        data.number = this.newNumber ? 'Ny nummer' : data.number
        if (data.product && data.number && !error) {
          // calculate points
          const product = this.products.filter(obj => obj.value === data.product)[0]
          if (this.multipleSale) {
            if (!this.family) {
              data.points = product.points
              data.children.forEach(child => {
                const childProduct = this.products.filter(obj => obj.value === child.product)[0]
                data.points = data.points + childProduct.points
              })
            } else {
              const members = data.children.length
              data.points = product.points * (members + 1)
            }
            data.children.forEach(child => {
              if (child.extra) {
                child.extra.forEach(obj => {
                  const extra = this.extraproducts.find(el => el.value === obj)
                  data.points = data.points + extra.points
                })
              }
            })
          } else {
            delete data.children
            data.points = product.points
          }
          if (data.extra) {
            data.extra.forEach(obj => {
              const extra = this.extraproducts.find(el => el.value === obj)
              data.points = data.points + extra.points
            })
          }
          data.points = Math.round(data.points * 100) / 100
          data.dateCreated = new Date(this.date)
          data.multipleSale = this.multipleSale
          data.family = this.family
          console.log(data)
          firestore.collection('tmb2c-sales').doc(data.id).update(data)
            .then(() => {
              openNotification('Salg Oppdatert')
              this.$router.push({ path: '/talkmoreB2C/sales/' })
            }).catch(err => {
              console.log(err)
              openNotification('Feil ved endring av salg', err)
            })
        } else {
          openNotification('Feil', 'Noen felt mangler')
        }
      } else {
        openNotification('Feil', 'Det er ikke registrert noe ep-bruker på deg, vennligst velg den du solgte på')
      }
    },
    addExtra(checkedValues) {
      console.log('checked = ', checkedValues)
      console.log('value = ', this.data.extra)
    },
    addMember() {
      console.log('check')
      if (!this.data.children) {
        this.data.children = [{ newNumber: false }]
        this.hasChildren = true
      } else {
        this.data.children.push({ newNumber: false })
        this.$forceUpdate()
      }
    },
    checkFamily() {
      const product = this.products.filter(obj => obj.value === this.data.product)[0]
      const family = product ? product.family : false
      this.multipleSale = family
      this.family = family
      console.log(family)
    },
    checkNumber(target) {
      const regex = /\D/g
      if (target === 99) {
        let num = this.data.number
        num = num.trim()
        const cleanNum = num.replace(regex, '')
        this.data.number = cleanNum
      } else {
        let num = this.data.children[target].number
        num = num.trim()
        const cleanNum = num.replace(regex, '')
        this.data.children[target].number = cleanNum
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
  },
  mounted() {
    firestore.collection('tmb2c-sales').doc(this.$route.params.id).get()
      .then(doc => {
        this.data = doc.data()
        if (this.data.children) {
          this.hasChildren = true
        }
        this.multipleSale = this.data.multipleSale
        this.family = this.data.family
        this.data.tempSeller = doc.data().seller.id
        this.data.id = doc.id
        this.loaded = true
        this.date = moment(new Date(doc.data().dateCreated.toDate()))
      })
    firestore.collection('tmb2c-products').where('status', '==', 'active').get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          if (doc.data().type === 'main') {
            this.products.push({
              value: String(doc.id),
              text: String(doc.data().name),
              key: String(doc.id),
              family: Boolean(doc.data().family),
              points: Number(doc.data().points),
              sequence: Number(doc.data().sequence),
            })
          } else if (doc.data().type === 'extra') {
            this.extraproducts.push({
              value: String(doc.id),
              label: String(doc.data().name),
              key: String(doc.id),
              family: Boolean(doc.data().family),
              points: Number(doc.data().points),
              sequence: Number(doc.data().sequence),
            })
          }
          // order products by sequence if none put it at the end
          this.products = this.products.sort((a, b) => {
            if (a.sequence === undefined) {
              return 1
            }
            if (b.sequence === undefined) {
              return -1
            }
            return a.sequence - b.sequence
          })
        })
        return firestore.collection('users').where('status', '==', 'active').get()
      }).then(snapshot => {
        snapshot.forEach(doc => {
          if (doc.data().talkmoreB2C) {
            if (doc.data().talkmoreB2C !== 'Bruker bestilt') {
              const user = {
                value: String(doc.data().talkmoreB2C),
                text: String(doc.data().talkmoreB2C),
                key: String(doc.data().talkmoreB2C),
              }
              this.epusers.push(user)
            }
          }
          const user = {
            value: String(doc.data().employeeID),
            text: String(doc.data().fullName),
            key: String(doc.data().fullName),
            id: String(doc.id),
            family: Boolean(doc.data().family),
            epuser: doc.data().talkmoreB2C,
          }
          this.users.push(user)
        })
        this.users = this.users.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
        this.epusers = this.epusers.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
      })
  },
}
</script>

<style>
</style>
